// src/components/UI/Footer.jsx
import React from 'react';
import '../styles/Footer.css';

const footerData = [
    {
        title: "Servicios",
        links: [
            { label: "Publicidad", url: "#" },
            { label: "Impresión Lithográfica", url: "#" },
            { label: "Diseño Web", url: "#" }
        ]
    },
    {
        title: "Soporte",
        links: [
            { label: "Precios", url: "#" },
            { label: "Documentación", url: "#" },
            { label: "Guías", url: "#" }
        ]
    },
    {
        title: "Compañía",
        links: [
            { label: "Sobre Nosotros", url: "#" },
            { label: "Trabaja con Nosotros", url: "#" },
            { label: "Blog", url: "#" }
        ]
    }
];

export const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="container">
                <div className="footer-container">
             
                    {footerData.map((column, index) => (
                        <div className="footer-column" key={index}>
                            <h3>{column.title}</h3>
                            <ul>
                                {column.links.map((link, linkIndex) => (
                                    <li key={linkIndex}>
                                        <a href={link.url}>{link.label}</a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
                <div className="footer-bottom">
                    <p>Copyright 2024, desarrollado por Copigraficassiera. Todos los derechos reservados.</p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
