import React from "react";
import "../styles/Hero.css";
import { motion } from "framer-motion";
import heroDarkimage from "../../assets/img/hero-img.png";
import LogoCopi from "../../assets/img/LogoCOPI.png";

export const Hero = () => {
  return (
    <section className="hero__section" id="Home">
      <div className="container">
        <motion.div 
          className="copiLogo" 
          initial={{ opacity: 0, y: -50 }} 
          animate={{ opacity: 1, y: 0 }} 
          transition={{ duration: 1.2 }}
        >
          <img src={LogoCopi} alt="Logo Copi" />
        </motion.div>
  
        <div className="hero__wrapper">
          <motion.div 
            className="hero__content"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 1.5 }}
          >
            <div>
              <h2 className="highlight">Quienes Somos</h2>
            </div>
            <p className="description">
              Somos una empresa con 40 años de experiencia en el mercado,
              enfocados en la solución de cualquier necesidad impresa, digital o
              publicitaria. Nuestra creatividad y soluciones urgentes nos
              permiten ofrecer tiempos de entrega rápidos y servicios totalmente
              personalizados.
            </p>
            
            <div className="hero__btns">
              <motion.button 
                className="primary__btn"
                whileHover={{ scale: 1.1, backgroundColor: "#f8e112" }}
                whileTap={{ scale: 0.95 }}
                style={{ padding: "12px 24px", fontSize: "1.2rem" }}
              >
                Comienza ahora
              </motion.button>
              <motion.button 
                className="secondary__btn"
                whileHover={{ scale: 1.1, backgroundColor: "#555" }}
                whileTap={{ scale: 0.95 }}
                style={{ padding: "12px 24px", fontSize: "1.2rem" }}
              >
                Descubre más
              </motion.button>
            </div>
          </motion.div>
          
          <motion.div 
            className="hero__image"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 1.2 }}
            style={{ margin: "20px auto", maxWidth: "80%" }}
          >
            <img src={heroDarkimage} alt="hero-img" style={{ maxWidth: "100%", height: "auto" }} />
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
