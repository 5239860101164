import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import "../styles/Services.css";

const services = [
  {
    icon: "🖨️",
    title: "Impresión Digital",
    description:
      "Ofrecemos impresión digital de alta calidad para cualquier tipo de documento o imagen que necesites.",
  },
  {
    icon: "📦",
    title: "Packaging",
    description:
      "Diseño y producción de empaques personalizados para productos de todas las industrias.",
  },
  {
    icon: "📚",
    title: "Publicaciones",
    description:
      "Impresión y encuadernación de libros, revistas, catálogos y otros materiales impresos maquetados al gusto.",
  },
  {
    icon: "🎨",
    title: "Diseño Gráfico",
    description:
      "Servicios completos de diseño gráfico para todas sus necesidades de branding y marketing.",
  },
  {
    icon: "🌐",
    title: "Diseño Web",
    description:
      "Creación y desarrollo de sitios web atractivos y funcionales para mejorar su presencia en línea.",
  },
  {
    icon: "🔧",
    title: "Mantenimiento",
    description:
      "Servicios de mantenimiento y soporte técnico para garantizar que sus sistemas funcionen sin problemas.",
  },
  {
    icon: "🪪",
    title: "Empresas De Seguridad",
    description:
      "Servicios de dotacion de material litografico especifico y personalizado para empresas de seguridad.",
  },
  {
    icon: "🧾",
    title: "Escaneo de Documentos",
    description:
      "Servicios de escaneo y almacenamiento de informacion importante o sencible con altos estandares de seguridad.",
  },
];

export const Services = () => {
  const servicesRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate");
        }
      });
    }, { threshold: 0.3 });

    if (servicesRef.current) {
      observer.observe(servicesRef.current);
    }

    return () => {
      if (servicesRef.current) {
        observer.unobserve(servicesRef.current);
      }
    };
  }, []);

  return (
    <section className="services-section" id="Service" ref={servicesRef}>
      <div className="container">
        <div className="services__top-content">
          <h2>Ahorra tiempo con nuestros servicios empresariales</h2>
          <h2 className="highlight">¡Los mejores servicios!</h2>
        </div>
        <div className="services">
          {services.map((service, index) => (
            <motion.div
              className="service-item"
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              whileHover={{
                scale: 1.05, // Aumenta el tamaño al pasar el mouse
                rotate: 2, // Añade una ligera rotación
                boxShadow: "0 12px 20px rgba(0, 0, 0, 0.2)", // Sombra más intensa
              }}
              whileTap={{ scale: 0.95 }} // Efecto de compresión al hacer clic
            >
              <div className="service-icon">{service.icon}</div>
              <div className="service-title">{service.title}</div>
              <div className="service-description">{service.description}</div>
              <button className="learn-more-btn">Conocer más</button>
            </motion.div>
          ))}
          <button className="secondary__btn-service">
            CONOCE MÁS DE NUESTROS SERVICIOS AQUÍ
          </button>
        </div>
      </div>
    </section>
  );
};

export default Services;
