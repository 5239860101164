// src/components/UI/EmailGUI.jsx
import React, { useState } from 'react';
import '../styles/EmailGUI.css'; 
import LogoWhatsap from "../../assets/img/download.png";

export const EmailGUI = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');

    const handleSubmit = (event) => {
        event.preventDefault();

        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailPattern.test(email)) {
            setError('Por favor, introduce un correo válido.');
            return;
        }

        console.log(`Email entered: ${email}`);
        setEmail('');
        setError('');
    };

    return (
        <section className="subscribe-section">
            <div className="container">
                <h2>¿Interesado?</h2>
                <h2>Cuentanos más sobre tu <span className="highlight">idea</span> y suscríbete!</h2>
                <form className="subscribe-form" onSubmit={handleSubmit}>
                    <input
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                    <button type="submit">Suscríbete Ahora</button>
                </form>
                {error && <p className="error-message">{error}</p>}
            </div>

            {/* Botón flotante de WhatsApp */}
            <a
                href="https://wa.me/573102486169" // Reemplaza 'yourwhatsappnumber' con el número real
                className="whatsapp-button"
                target="_blank"
                rel="noopener noreferrer"
            >
            <img src={LogoWhatsap} alt="WTS-img" style={{ maxWidth: "100%", height: "auto" }} />
            </a>
        </section>
    );
};

export default EmailGUI;
