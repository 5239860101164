
import './App.css';

import { Header } from './components/header/Header';
import {Hero} from './components/UI/Hero'
import {Counter} from'./components/UI/Counter'
import {Services} from './components/UI/Services'
import {About} from './components/UI/About'
import ServiciosProductos from './components/UI/ServiciosProductos';
import {Blog} from './components/UI/Blog'
import {Testimonials} from './components/UI/Testimonial'
import {EmailGUI} from './components/UI/EmailGUI'
import {Footer} from './components/UI/Footer'
import {Libros} from './components/UI/Libros'

function App() {
  return (
  <>
    <Header/>
    <Hero/>  
    <Counter/>
    <About/>
    <Services/>
    <ServiciosProductos/>
    <Blog/>
    <Testimonials/>
    <EmailGUI/>
    <Footer/>
    <Libros/>
 
  </>
  );
  
}

export default App;
