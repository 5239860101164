import React from "react";
import "../styles/Blog.css";
import videoImg from "../../assets/img/video.png";
import casestudy from "../../assets/img/case-study.png";
import article from "../../assets/img/article.png";

const BlogData = {
  blogPosts: [
    {
      title: "Estrategias de Impresión Digital",
      excerpt:
        "Explora cómo nuestras técnicas avanzadas de impresión digital pueden transformar tus proyectos.",
      date: "Julio 26, 2024",
      img: videoImg,
    },
    {
      title: "5 Consejos para un Branding Efectivo",
      excerpt:
        "Aprende los mejores consejos para crear una marca fuerte y efectiva con nuestros servicios.",
      date: "Julio 15, 2024",
      img: article,
    },
    {
      title: "Casos de Estudio: Éxito con Nuestros Servicios",
      excerpt:
        "Descubre casos de éxito donde nuestros servicios han hecho una diferencia significativa.",
      date: "Julio 20, 2024",
      img: casestudy,
    },
  ],
};

export const Blog = () => {
  return (
    <section className="Blog-section" id="Blog">
      <div className="container">
        <div className="blog__top-content">
          <h2>
            Últimos Posts del Blog
            <br />
            Echa un vistazo a nuestros
            <span className="highlight"> artículos recientes</span>
          </h2>
        </div>
        <div className="blog__wrapper">
          {BlogData.blogPosts.map((post, index) => (
            <div className="blog-post" key={index}>
              <div className="blog__img">
                <img src={post.img} alt={post.title} />
              </div>
              <h3>{post.title}</h3>
              <p>{post.excerpt}</p>
              <span className="date">{post.date}</span>
              <a
                href="https://www.copigraficassierra.com"
                className="learn__more"
              >
                <i className="ri-arrow-right-line"> Leer Más</i>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Blog;
