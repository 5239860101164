// src/3dObjects/ThreeDView.js
import React from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';

const Model = () => {
  const { scene } = useGLTF('/models/variety_of_books/scene.gltf');
  return <primitive object={scene} scale={[4, 4, 4]} />;
};

const ThreeDView = () => {
  return (
    <Canvas
      style={{ width: '100%', height: '100vh' }}
      camera={{ position: [20, 0, 20], fov: 75 }}
    >
      <ambientLight intensity={3} />
      <directionalLight position={[100, 100, 100]} intensity={1} castShadow />
      <Model />
      <OrbitControls />
    </Canvas>
  );
};

export default ThreeDView;
