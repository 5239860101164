import React from "react";
import '../styles/ServiciosProductos.css';

const ServiciosProductos = () => {
  return (
    <section className="servicios-productos">
      <div className="container">
        <h1>¡Todo en un solo lugar!</h1>
        <p className="description">
          Nos caracterizamos por brindar un servicio integral, productos y servicios
          funcionales y favorables para el cuidado del medio ambiente y tiempos de entrega
          cortos, ya que contamos con maquinaria propia que nos evita procesos de
          intermediación.
        </p>
        <div className="botones">
          <button className="btn primary__btn-service">VER PORTAFOLIO LITOGRAFÍA</button>
          <button className="btn secondary__btn-service">VER CATALOGOS PROMOCIONALES</button>
        </div>

        <div className="benefits marquee">
            <div className="service-item animate">
                <span className="service-icon">👍</span>
                <p className="service-title">Calidad Garantizada</p>
            </div>
            <div className="service-item animate">
                <span className="service-icon">💰</span>
                <p className="service-title">Facilidad en el Pago</p>
            </div>
            <div className="service-item animate">
                <span className="service-icon">🏠</span>
                <p className="service-title">Servicio a Domicilio</p>
            </div>
            <div className="service-item animate">
                <span className="service-icon">✈️</span>
                <p className="service-title">Envíos Nacionales</p>
            </div>
        </div>
      
      
      </div>
    </section>
  );
};

export default ServiciosProductos;
