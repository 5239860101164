import React, { useRef, useEffect } from 'react';
import './Header.css';
import LogoCopigraficass from '../../assets/img/CopigraficassierraLOGO-removebg-preview.png';
import Logo30AñosCopigraficass from '../../assets/img/30años.png';

const nav_links = [
    { path: '#Home', display: 'Inicio' },
    { path: '#About', display: 'Sobre Nosotros' },
    { path: '#Service', display: 'Servicios' },
    { path: '#Projects', display: 'Proyectos' },
    { path: '#Blog', display: 'Blog' }
];

export const Header = () => {
    const headerRef = useRef(null);

    const headerFunc = () => {
        if (document.body.scrollTop > 80 || document.documentElement.scrollTop > 80) {
            headerRef.current.classList.add('header__shrink');
        } else {
            headerRef.current.classList.remove('header__shrink');
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', headerFunc);
        return () => {
            window.removeEventListener('scroll', headerFunc);
        };
    }, []);

    const handleClick = (e) => {
        e.preventDefault();

        const targetAttr = e.target.getAttribute('href');
        if (targetAttr) {
            const targetElement = document.querySelector(targetAttr);
            if (targetElement) {
                const location = targetElement.offsetTop;

                window.scrollTo({
                    left: 0,
                    top: location - 80,
                    behavior: 'smooth'
                });
            }
        }
    };

    return (
        <header ref={headerRef} className="header">
            <div className="container">
                <div className="nav__wrapper">
                    {/* Logo principal */}
                    <div className="logo">
                        <img 
                            className='logo__copigraficassierra' 
                            src={LogoCopigraficass} 
                            alt="Logo Copigraficassierra" 
                            style={{ maxWidth: '120px', transition: 'all 0.3s ease' }}
                        />
                    </div>

                    {/* Navegación */}
                    <div className="navigation">
                        <ul className="menu">
                            {nav_links.map((item, index) => (
                                <li key={index} className="menu__item">
                                    <a 
                                        href={item.path} 
                                        onClick={handleClick} 
                                        className="menu__link"
                                    >
                                        {item.display}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Logo 30 años */}
                    <div className="anniversary-logo">
                        <img 
                            className='logo__30years' 
                            src={Logo30AñosCopigraficass} 
                            alt="30 años Copigraficassierra" 
                            style={{ maxWidth: '100px', transition: 'all 0.3s ease' }}
                        />
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
