import React, { useState, useEffect, useRef } from "react";
import "../styles/About.css";
import aboutImage1 from "../../assets/img/about-us.jpg";
import aboutImage2 from "../../assets/img/team-01.png";
import aboutImage3 from "../../assets/img/team-02.png";
import { motion } from "framer-motion";

const aboutData = {
  title: "¿Por qué elegirnos?",
  subtitle: "Especialistas en soluciones creativas y urgentes",
  description:
    "Con 40 años de experiencia en el mercado, en Copigraficassiera nos dedicamos a ofrecer soluciones impresas y digitales personalizadas. Nuestro compromiso es entregar trabajos de alta calidad en tiempos de entrega rápidos, adaptándonos a cualquier necesidad de publicidad de marca.",
  features: [
    { icon: "📡", title: "Proceso eficiente", description: "Nuestro proceso de trabajo es rápido y eficaz, permitiéndonos cumplir con plazos ajustados sin comprometer la calidad." },
    { icon: "👥", title: "Equipo dedicado", description: "Contamos con un equipo altamente calificado y comprometido en brindar soluciones innovadoras y personalizadas para cada cliente." },
    { icon: "⌚", title: "Soporte 24/7", description: "Ofrecemos soporte continuo para asegurar que tus necesidades sean atendidas en cualquier momento." },
    { icon: "😂", title: "Trabajadores especializados", description: "Nuestro equipo está compuesto por especialistas en diversas áreas, asegurando un enfoque integral en cada proyecto." },
  ],
  images: [aboutImage1, aboutImage2, aboutImage3],
};

export const About = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [visible, setVisible] = useState(Array(aboutData.features.length).fill(false));

  const nextImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % aboutData.images.length);
  };

  const prevImage = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex - 1 + aboutData.images.length) % aboutData.images.length);
  };

  const ref = useRef([]);

  const checkVisibility = () => {
    const newVisible = [...visible];
    ref.current.forEach((el, index) => {
      if (el && el.getBoundingClientRect().top < window.innerHeight) {
        newVisible[index] = true;
      }
    });
    setVisible(newVisible);
  };

  useEffect(() => {
    checkVisibility();
    window.addEventListener("scroll", checkVisibility);
    return () => window.removeEventListener("scroll", checkVisibility);
  }, []);

  return (
    <section className="about-section" id="About">
      <div className="container">
        <div className="about__content">
          <motion.div className="about__text" initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
            <h2>{aboutData.title}</h2>
            <h3>{aboutData.subtitle}</h3>
            <p>{aboutData.description}</p>
            <div className="about__features">
              {aboutData.features.map((feature, index) => (
                <motion.div
                  ref={(el) => (ref.current[index] = el)}
                  className="feature"
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={visible[index] ? { opacity: 1, y: 0 } : {}}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <div className="icon">{feature.icon}</div>
                  <div className="text">
                    <h4>{feature.title}</h4>
                    <p>{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>
          <motion.div className="about__image" initial={{ scale: 0.9, opacity: 0 }} animate={{ scale: 1, opacity: 1 }} transition={{ duration: 0.5 }}>
            <img src={aboutData.images[currentImageIndex]} alt="About Us" />
            <div className="slider-buttons">
              <button onClick={prevImage}>&lt;</button>
              <button onClick={nextImage}>&gt;</button>
            </div>
          </motion.div>
        </div>
      </div>
    </section>
  );
};

export default About;
