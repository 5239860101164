// src/components/Libros.js
import React from 'react';
import ThreeDView from '../../3dObjects/ThreeDView'; // Ajusta la ruta según tu estructura
import '../styles//Libros.css'; // Importa el archivo CSS

export const Libros = () => {
  return (
    <section className="libros-section">
      <div className="libros-container">
        <div className="libros-info">
          <h2 className="libros-subtitle">Cómo hacemos los libros</h2>
          <p className="libros-description">
            En nuestra empresa, la producción de libros sigue un proceso meticuloso y detallado. Utilizamos las
            últimas tecnologías en impresión y encuadernación para asegurar la máxima calidad en cada ejemplar.
            Desde el diseño hasta el producto final, cada etapa está supervisada por expertos en el campo.
          </p>
          <p className="libros-description">
            Nuestro equipo trabaja con materiales de alta calidad y tecnología avanzada para ofrecer productos
            que cumplan con los más altos estándares del mercado. Nos enorgullece ofrecer soluciones personalizadas
            que se ajusten a las necesidades específicas de nuestros clientes.
          </p>
        </div>

        <div className="libros-model">
          <ThreeDView />
        </div>
      </div>
    </section>
  );
};

export default Libros;
