// src/components/UI/Testimonials.jsx
import React, { useState } from 'react';
import '../styles/Testimonial.css';

import testimonyPersonOne from '../../assets/img/Agulas de oro.png';
import testimonyPersonTwo from '../../assets/img/Logoseguridadcentral.png';
import testimonyPersonThird from '../../assets/img/TAC_Seguridad.png';

const testimonials = [
  {
    text: "La colaboración con Copigraficassierra ha sido fundamental para nuestra estrategia de branding. Su experiencia en soluciones de impresión digital nos permitió destacar en el mercado con productos de alta calidad y excelente diseño.",
    name: "Águilas de Oro",
    title: "Cliente Satisfecho",
    image: testimonyPersonOne
  },
  {
    text: "Trabajar con Copigraficassierra ha sido una experiencia excepcional. Sus soluciones personalizadas para impresión y diseño nos ayudaron a mejorar nuestra presencia en el mercado y alcanzar nuestros objetivos de manera efectiva.",
    name: "Seguridad Central",
    title: "Cliente Satisfecho",
    image: testimonyPersonTwo
  },
  {
    text: "Copigraficassierra ha demostrado ser un socio confiable y profesional en todas nuestras necesidades de impresión. Su capacidad para entregar soluciones rápidas y de alta calidad ha sido clave para nuestro éxito.",
    name: "TAC Seguridad",
    title: "Cliente Satisfecho",
    image: testimonyPersonThird
  }
];

export const Testimonials = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(false);

  const handleNext = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
      setFade(false);
    }, 500); // Duración de la animación de desvanecimiento
  };

  const handlePrev = () => {
    setFade(true);
    setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
      setFade(false);
    }, 500); // Duración de la animación de desvanecimiento
  };

  return (
    <section className="testimonials-section">
      <div className="container">
        <h2>Testimonios</h2>
        <p>de algunos de nuestros mas de <span>1000 clientes</span></p>
        <div className="testimonial">
          <div className="testimonial-content">
            <div className="testimonial-info">
              <img src={testimonials[currentIndex].image} alt={testimonials[currentIndex].name} />
              <div className="testimonial-text-wrapper">
                <p className={`testimonial-text ${fade ? 'fade' : ''}`}>{testimonials[currentIndex].text}</p>
                <h4>{testimonials[currentIndex].name}</h4>
                <p>{testimonials[currentIndex].title}</p>
              </div>
            </div>
            <div className="testimonial-controls">
              <button onClick={handlePrev}>&lt;</button>
              <button onClick={handleNext}>&gt;</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
