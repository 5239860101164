import React, { useState, useEffect, useMemo, useRef } from 'react';
import '../styles/Counter.css';

export const Counter = () => {
  const counters = useMemo(() => [
    { number: 150, label: "Proyectos" },
    { number: 300, label: "Clientes" },
    { number: 1200, label: "Ventas" },
    { number: 20, label: "Empleados" },
  ], []);

  const [counts, setCounts] = useState(counters.map(() => 0));
  const [hasAnimated, setHasAnimated] = useState(false); // Controlar la animación
  const counterRef = useRef(null); // Referencia al contenedor del contador

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting && !hasAnimated) {
          setHasAnimated(true); // Marcar como animado para que solo se ejecute una vez
          const intervals = counters.map((counter, index) => {
            const increment = counter.number / 100;
            return setInterval(() => {
              setCounts((prevCounts) => {
                const newCounts = [...prevCounts];
                if (newCounts[index] < counter.number) {
                  newCounts[index] = Math.min(newCounts[index] + increment, counter.number);
                }
                return newCounts;
              });
            }, 40);
          });

          // Limpiar intervalos cuando el componente se desmonte
          return () => intervals.forEach(clearInterval);
        }
      });
    }, { threshold: 0.3 }); // El umbral controla cuánto de la sección debe estar visible antes de activar

    if (counterRef.current) {
      observer.observe(counterRef.current);
    }

    return () => {
      if (counterRef.current) {
        observer.unobserve(counterRef.current);
      }
    };
  }, [counters, hasAnimated]);

  return (
    <section className="counter__section">
      <div className="container">
        <div className="counter" ref={counterRef}>
          {counters.map((counter, index) => (
            <div className={`counter__item ${hasAnimated ? 'animate' : ''}`} key={index}>
              <div className="counter__number">{Math.floor(counts[index])}</div>
              <div className="counter__label">{counter.label}</div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Counter;
